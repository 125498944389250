body {
  font-family: 'Outfit';
  background: #0F0F0F;
}

@font-face {
  font-family: 'Adderley';
  src: url('fonts/Adderley Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.adderley{
  font-family: 'Adderley', sans-serif;
}

.max-w-1440 {
  max-width: 1440px;
  margin: 0 auto;
}

.blackandwhiteimg{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: black; 
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #06121c; 
  height:1px;
  width: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #06121c; 
  width: 2px;
}

.banner{
  background-image: url('./assets/images/tatto.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
  position: relative;
  
}

.bg-banner2{
  background-image: url('./assets/images/bg-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  

}

.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #53f7a9; 
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
 
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
}